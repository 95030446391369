import { Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, Link } from "@mui/material";
import React, { useState } from "react";
import Navbar from "./Components/Navbar";
import StorageUsed from "./Components/StorageUsed";
import Plan from "./Components/Plan";
import OptionsTable from "./Components/OptionsTable";
import CalculateIcon from '@mui/icons-material/Calculate';
import PollIcon from '@mui/icons-material/Poll';
import MessageIcon from '@mui/icons-material/Message';

function App() {

  const dropboxPlans = [
    { name: "Plus", abc: 119.88, bsq: 2000, buq: 1 },
    { name: "Professional", abc: 198.96, bsq: 3000, buq: 1 },
    { name: "Standard", abc: 540, bsq: 5000, buq: 3 }
  ];

  const otherPlans = [
    { name: "Standard", provider: "Google", abc: 29.99, bsq: 200, buq: 5 },
    { name: "Premium 2TB", provider: "Google", abc: 99.99, bsq: 2000, buq: 5 },
    { name: "Premium 5TB", provider: "Google", abc: 249.99, bsq: 5000, buq: 5 },
    { name: "365 Basic", provider: "Microsoft", abc: 19.99, bsq: 100, buq: 5 },
    { name: "365 Personal", provider: "Microsoft", abc: 69.99, bsq: 1000, buq: 1 },
    { name: "365 Business Basic", provider: "Microsoft", abc: 19.99, bsq: 100, buq: 1 }
  ];

  const [currentPlan, setCurrentPlan] = useState({
    dbxPlanName: dropboxPlans[0].name,
    storageUsed: 0,
    actualUsers: 1,
    cheapestAlternativePlanName: "365 Basic"
  });


  return (
    <div className="App">
      <Navbar />
      <Stack marginTop={5} marginLeft={10} marginRight={10}>
        <Typography variant="subtitle1">
          <Link href="https://www.dropbox.com/">Dropbox</Link>, once a pioneer in cloud-based file storage and sharing, has fallen behind other service providers like <Link href="https://workspace.google.com/">Google</Link> and <Link href="https://www.office.com/">Microsoft</Link> in customer experience, value for your money, and innovative product features. The other providers offer cheaper storage plans, a broader range of productivity tools, and seamless integration with collaboration, conferencing and workflow automation tools.
          <br />
          <br />
          We are a group of industry veterans who can help. Contact us for seamless file migration to another provider or to get advice on negotiating a lower price with Dropbox.
          <br />
          <br />
        </Typography>
      </Stack>
      <Stack>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSclBhRI8CXdULZ7hh_9NmTY_AoPtdRrpFxJ9AukYi-G5zJQSA/viewform?embedded=true" height="2000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Stack>
    </div>
  );
}

export default App;